import React from "react";
import logo from "./mlm_logo.png";
import "./app.css";

import { Card, Button, Jumbotron, Container } from "reactstrap";

function App() {
  return (
    <Container className="h-100 d-flex">
      <Jumbotron className="my-auto" style={{ textAlign: "center" }}>
        <Card style={{ width: "14rem" }} className="mx-auto">
          <img src={logo} className="App-logo mx-auto" width="200" alt="logo" />
        </Card>
        <h2 className="display-4 mt-3">Multi Level Memory</h2>
        <p className="lead">
          Multi Level Memory Technology, Inc. (MLM) is a privately held company
          located in the High Sierras. MLM's pioneering concepts in the areas of
          Multi-Level Cell (MLC) revolutionizes the way electronic and
          multi-media data are stored and reproduced.
        </p>

        <p className="lead">
          Its mission is to be a leading innovator of MLC concepts and
          implementations by developing creative solutions for multi-level cell
          technology, array architectures, algorithms, circuit & layout
          techniques, etc.
        </p>
        <p className="lead">
          <a href="mailto:mtbcourier2@gmail.com">
            <Button color="primary">Contact</Button>
          </a>
        </p>
        <hr className="my-2" />
        <p>
          Multi Level Memory® and MLM® are registered trademarks of Multi Level
          Memory Technology, Inc.
        </p>
      </Jumbotron>
    </Container>
  );
}

export default App;
